<template>
  <section
    class="container-fluid d-flex flex-column justify-content-center align-items-center h-100">
    <h1>{{ code }}</h1>
    <h2>{{ innerMessage }}</h2>
    <router-link :to="{ name: 'root' }">
      Take me back to Balloon
    </router-link>
  </section>
</template>

<script>
export default {
  name: 'ErrorView',

  props: {
    code: { type: String, default: '404' },
    message: { type: String, default: '' },
  },

  computed: {
    innerMessage() {
      if (this.message) {
        return this.message;
      }

      if (this.code === '401') {
        return 'You are not authenticated';
      }

      if (this.code === '403') {
        return "You don't have permission to access this page";
      }

      return 'Not found';
    },
  },
};
</script>

<style lang="less" scoped>
h1 {
  text-align: center;
  font-size: 12rem;
  line-height: 12rem;
}

h2 {
  text-align: center;
  font-size: 4rem;
  line-height: 5rem;
  margin: 3rem;
}

a {
  color: @navy;
  &:hover {
    text-decoration: underline;
  }
}
</style>
